import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index-page",
    component: () => import("../pages/home-page/App.vue")
  },
  {
    path: "/phone-number-appointment",
    name: "phone-number-appointment",
    component: () => import("../pages/phone-number-appointment/App.vue")
  },
  {
    path: "/account-log-off",
    name: "account-log-off",
    component: () => import("../pages/account-log-off/App.vue")
  },
  {
    path: "/user-policy",
    name: "user-policy",
    component: () => import("../pages/user-policy/App.vue")
  },
  {
    path: "/utils-page",
    name: "utils-page",
    component: () => import("../pages/utils-page/App.vue")
  },
  {
    path: "/home-page",
    name: "home-page",
    component: () => import("../pages/home-page/App.vue")
  },
];

const router = new VueRouter({
  routes
});

export default router;